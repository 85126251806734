/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* RESET */

body{
    overflow: hidden;
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

:root {
    --toastify-color-info: #0ea5e9;
    --toastify-color-success: #14b8a6;
    --toastify-color-warning: #facc15;
    --toastify-color-error: #ef4444;
    --primary: #B4FF00;
    --secondary: #132038;
    --background: #f5f5f5;
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-form-item{
    margin-bottom: 12px;
}

/* OVERRIDES */

:where(.css-dev-only-do-not-override-1s3dcof).ant-btn-primary{
    background-color: #1677ff;
}

.ant-table-title:empty {
    padding: 0px;
}

.ant-menu-item-group .ant-menu-item-group-title{
    color: #828895 !important;
}

:where(.css-dev-only-do-not-override-1pnkyu9).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,:where(.css-dev-only-do-not-override-1pnkyu9).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active{
    background: rgb(42,54,75);
    background: linear-gradient(90deg, rgba(42,54,75,1) 0%, rgba(19,32,56,1) 100%);
}

.icon-datepicker span{
    margin-left: -7px !important;
}
.icon-datepicker input{
    display: none;
}

.icon-datepicker{
    box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-p6ujno).ant-steps.ant-steps-navigation .ant-steps-item::before {
    background-color: #B4FF00;
}

:where(.css-p6ujno).ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
    background-color: #B4FF00;
}

.ant-steps-item-title {
    color: rgba(0,0,0) !important;
}